import $ from 'jquery'
import { debounce } from 'lodash'
export default class PasswordStrength {
    constructor(inputPasswordId) {
        this.inputPassword = $(inputPasswordId)
        const debounced = debounce(() => this.changePassword(), 200, {
            maxWait: 500,
        })
        this.inputPassword.on('keyup change', () => debounced())
        this.zxcvbn = require('zxcvbn')
        this.validate()
        this.form = this.inputPassword.parents('form')
        this.form.on('submit', (event) => this.submitForm(event))
    }

    submitForm(event) {
        if (this.statusValidate) return true
        event.preventDefault()
        return false
    }

    changePassword() {
        const password = this.inputPassword.val()
        if (password === '') return this.emptyPassword()
        const score = this.zxcvbn(password).score
        if (score === null || score <= 2) return this.riskyPassword()
        if (score <= 3) return this.moderatePassword()
        return this.strongPassword()
    }

    emptyPassword() {
        this.inputPassword.removeClass('is-invalid')
        this.inputPassword.removeClass('border-warning')
        this.inputPassword.removeClass('is-valid')
        this.validate()
    }

    riskyPassword() {
        this.inputPassword.removeClass('is-valid')
        this.inputPassword.removeClass('border-warning')
        this.inputPassword.addClass('is-invalid')
        this.invalidate()
    }

    moderatePassword() {
        this.inputPassword.removeClass('is-invalid')
        this.inputPassword.removeClass('is-valid')
        this.inputPassword.addClass('border-warning')
        this.validate()
    }

    strongPassword() {
        this.inputPassword.removeClass('border-warning')
        this.inputPassword.removeClass('is-invalid')
        this.inputPassword.addClass('is-valid')
        this.validate()
    }

    invalidate() {
        this.statusValidate = false
    }

    validate() {
        this.statusValidate = true
    }
}

window.PasswordStrength = PasswordStrength
