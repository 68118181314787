import $ from 'jquery'
export default class ShowPassword {
    constructor(inputId) {
        this.$input = $(`input#${inputId}`)
        const eyeContainer = this.$input.next('.eye')
        this.$showInput = eyeContainer.find('.show-icon')
        this.$hideInput = eyeContainer.find('.hide-icon')
        this.$showInput.on('click', () => this.showPasswordAction())
        this.$hideInput.on('click', () => this.hidePasswordAction())
    }

    showPasswordAction() {
        this.$input.prop('type', 'text')
        this.$showInput.hide()
        this.$hideInput.show()
    }

    hidePasswordAction() {
        this.$input.prop('type', 'password')
        this.$hideInput.hide()
        this.$showInput.show()
    }
}
window.ShowPassword = ShowPassword
